import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import { useTranslation } from 'react-i18next';
import { TextField, MenuItem, Button, RadioGroup, FormControlLabel, Radio, Slide } from '@material-ui/core';
import { useStyles } from "./styles";
import i18n, { updateResources } from '../../../i18n';
import axios from 'axios';
import { API_URL_LOCALS } from "../../../environment";
import ReuseableModal from '../../../components/modal/reuseModal';
import { Scrollbars } from 'react-custom-scrollbars';
// import { useTranslation } from "react-i18next";

const Language = (props) => {
    const { t } = useTranslation();

    const { languages, restaurantSuccess, drawerOpen, currentLanguage } = props;
    console.log("currentLanguage", currentLanguage);
    // console.log("languages",languages);
    // console.log("rest Success", restaurantSuccess);

    const { i18n } = useTranslation();
    const classes = useStyles();
    const [languageList, setLanguageList] = useState([]);
    console.log("languageList",languageList);
    
    const [selectedLanguageTitle, setSelectedLanguageTitle] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [tempSelectedLanguageTitle, setTempSelectedLanguageTitle] = useState(selectedLanguageTitle);
    const [responseData, setResponseData] = useState(null);
    const [restaurantData, setRestaurantData] = useState(null);
    const [openLangModal, setOpenLangModal] = useState(false);
    const [default_lang_id,setDefaultLang_id] = useState(null);
    
    useEffect(() => {
        props.getLanguages();
    }, []);

    useEffect(() => {
        if (restaurantSuccess) {
            setRestaurantData(restaurantSuccess.data);
            setDefaultLang_id(restaurantSuccess.data.default_lang_id);
        }
    }, [restaurantSuccess]);


    // useEffect(() => {
    //     if (restaurantData && restaurantData.default_lang_id) {
    //         setDefaultLang_id(restaurantData.default_lang_id);
    //         setTempSelectedLanguageTitle(restaurantData.default_lang_id); // Set initial selected value
    //         console.log("lang title",tempSelectedLanguageTitle);
            
    //     }
    // }, [restaurantData]);
    


    useEffect(() => {
        if (languages) {
            setLanguageList(languages.data);
        }
    }, [languages]);

    useEffect(() => {
        if (default_lang_id && languageList.length > 0) {
            // Set the default selected language based on default_lang_id
            const defaultLanguage = languageList.find(lang => lang._id === default_lang_id);
            if (defaultLanguage) {
                setTempSelectedLanguageTitle(defaultLanguage.code); // Set the value to language code
            }
        }
    }, [default_lang_id, languageList]);

    useEffect(() => {
        if (languageList && languageList.length) {
            let defaultLanguage = null;
            if (localStorage.getItem('language')) {
                defaultLanguage = languageList.find(lang => lang._id === localStorage.getItem('language'));
                console.log("Default language:in useEffect " + defaultLanguage); 

            } else {
                defaultLanguage = languageList.find(lang => lang.title === 'English');
            }

            if (defaultLanguage) {
                if (restaurantData?.language_ids?.length > 2) {
                    setSelectedLanguageTitle(defaultLanguage.title);
                    setSelectedLanguage(defaultLanguage);
                } else if (restaurantData?.language_ids?.length === 2) {
                    setSelectedLanguage(defaultLanguage);
                    const filteredLanguages = languageList.filter(option => {
                        if (restaurantData?.language_ids) {
                            return restaurantData.language_ids.includes(option._id);
                        } else if (restaurantData?.default_lang_id) {
                            return restaurantData.default_lang_id === option._id;
                        } else {
                            return option.title === 'English';
                        }
                    });
                    // const alternateLanguage = filteredLanguages.find(lang => lang._id !== defaultLanguage._id);
                    // if (alternateLanguage) {
                    //     setSelectedLanguageTitle(alternateLanguage.title);
                    // } else {
                    //     setSelectedLanguageTitle(defaultLanguage.title);
                    // }
                }
            }
        }
    }, [languageList, restaurantData]);

    // const handleChange = async (event) => {
    //     const selectedTitle = event.target.value;
    //     console.log('selected title: ' + selectedTitle);
    //     console.log("languages in handleChange: " + props.languages.data);

    //     const selectedLanguage = props.languages.data.find(option => option.title === selectedTitle);
    //     console.log("Selected language: '" + selectedLanguage);

    //     setSelectedLanguageTitle(selectedTitle);
    //     setSelectedLanguage(selectedLanguage);
    // };
    // const handleToggle = () => {
    //     const filteredLanguages = languageList.filter(option => {
    //         if (restaurantData?.language_ids){
    //             return restaurantData.language_ids.includes(option._id);
    //         } else if (restaurantData?.default_lang_id){
    //             return restaurantData.default_lang_id === option._id;
    //         } else {
    //             return option.title === 'English';
    //         }
    //     });
    //     const newLanguage = filteredLanguages.find(lang => lang._id !== selectedLanguage._id);
    //     const alternateLanguage = filteredLanguages.find(lang => lang._id !== newLanguage._id);
    //     setSelectedLanguage(newLanguage);
    //     setSelectedLanguageTitle(alternateLanguage ? alternateLanguage.title : newLanguage.title);
    // };


    const handleChange = async (selectedTitle) => {
        localStorage.setItem('selectedTitle', selectedTitle);
        console.log('selected title: ' + selectedTitle);
        // console.log("languages in handleChange: " + props.languages.data);
        const selectedLanguage = props.languages.data.find(option => option.code === selectedTitle);
        // console.log("Selected language: '" + JSON.stringify(selectedLanguage));
        // const finalselectedLanguage = JSON.stringify(selectedLanguage); 
        // console.log("finalselectedLanguage: '" + finalselectedLanguage._id);
        
        setSelectedLanguageTitle(selectedTitle);
        setSelectedLanguage(selectedLanguage);//finalselectedLanguage
        // console.log("selectedLanguage",selectedLanguage);    
        
        langModalClose();
    };

    useEffect(() => {
        if (selectedLanguage) {
            fetchAndSetData(selectedLanguage);
        }
    }, [selectedLanguage]);

    const fetchAndSetData = async (selectedLanguage) => {
        console.log("abc",selectedLanguage);
        
        props.setCurrentLanguage(selectedLanguage);
        // console.log("selectedLanguage: in fetchAndSetData " + selectedLanguage);
        // console.log("selectedLanguageid",selectedLanguage._id);
        try {
            const response = await axios.post(API_URL_LOCALS + '/keyword/getDetailsOfKeywordByLangId', {
                language_id: selectedLanguage._id
            });
            // console.log("response of get Lang by ID " + JSON.stringify(response));

            setResponseData(response.data.data);
            updateResources(selectedLanguage.code, response.data.data);
            localStorage.setItem("code", selectedLanguage.code);
            localStorage.setItem("language", selectedLanguage._id)
            i18n.changeLanguage(selectedLanguage.code);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const langModalOpen = () => {
        setOpenLangModal(true)
    }
    const langModalClose = () => {
        setOpenLangModal(false)
    }
    const selectedLanguages = languageList.find(lang => lang.code === selectedLanguageTitle);
    const selectedLang = () =>{
        
    }
    return (
        <div className='lang-btn-div'>
            <button className='language-toggle-btn' onClick={langModalOpen}>
                <img src={selectedLanguage?.flag ? selectedLanguage?.flag : '' } style={{height:'18px',width:'18px',marginRight:'5px'}} alt={selectedLanguages?.title} />
                {selectedLanguage?.title ? selectedLanguage?.title.slice(0,2) : languageList[38]?.title.slice(0,2) } {/*"English"*/}
            </button>

            <Slide up>
                <div>
                    <ReuseableModal open={openLangModal} onClose={langModalClose} className={classes.modal}>
                        <div className={classes.paper}>
                            <h2 className={currentLanguage?.left_to_right === 0 ? 'lng-text lng-text-rtl':'lng-text'}> {t('changeLanguage')}</h2>
                           
                            <Scrollbars autoHide style={{ maxHeight: '450px' }}>
                                <div >
                                    {/* <RadioGroup value={selectedLanguageTitle} onChange={handleChange}>  */}
                                    <RadioGroup value={ tempSelectedLanguageTitle} 
                                     onChange={(e) => setTempSelectedLanguageTitle(e.target.value)}>
                                        {languageList?.filter(option => {
                                            if (restaurantData?.language_ids)
                                                return restaurantData.language_ids.includes(option._id);
                                            else if (restaurantData?.default_lang_id)
                                                return restaurantData.default_lang_id === option._id;
                                            else
                                                return option.title === 'English';
                                        }).map(option => (
                                            <FormControlLabel className={props.currentLanguage?.left_to_right === 0 ? 'radio-form-main-left' : 'radio-form-main'}
                                                key={option.code}
                                                value={option.code}
                                                control={<Radio color='Primary' className={props.currentLanguage?.left_to_right === 0 ? classes.radioFormLeft : classes.radioForm} /> }
                                                label={
                                                    <span className={props.currentLanguage?.left_to_right === 0 ? 'flag-inner-div-left' : 'flag-inner-div'}>
                                                        {/* <span className='flg-div'></span> */}
                                                        <span className={currentLanguage.left_to_right === 0 ? 'flag-icon-rtl' : 'flag-icon'} > {/**className={`flag-icon flag-icon-${''}`} style={{ marginRight: 22 }} */}
                                                            <img src={option?.flag} style={{height:'40px',width:'40px'}} />
                                                        </span>
                                                        {option.title}
                                                    </span>
                                                }
                                            />
                                        ))}
                                    </RadioGroup>
                                </div>
                            </Scrollbars>

                            <div className='btn-langs'>
                                {/* <button className='cnfrm-lang' onClick={handleChange} value={selectedLanguageTitle} >Confirm</button>
                                 */}
                                <button className='cnfrm-lang' onClick={() => handleChange(tempSelectedLanguageTitle)}>{t('confirm')}</button>

                                <button className='cncel-lng' onClick={langModalClose} >{t('cancle')}</button>
                            </div>

                        </div>
                    </ReuseableModal>
                </div>
            </Slide>

        </div>
    );
};

const mapStateToProps = ({ languageReducer, restaurantReducer }) => {
    const { loading, error, success, languages, keywords, langDetail, currentLanguage } = languageReducer;
    const { restaurantSuccess } = restaurantReducer;
    return { loading, error, success, languages, keywords, langDetail, restaurantSuccess, currentLanguage };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getLanguages: () => dispatch(actions.fetchAllLanguagesRequest()),
        setCurrentLanguage: (languageId) => dispatch(actions.setCurrentLanguage(languageId)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Language);
