import React from "react";
import PayModel from '../../../components/modal/reuseModal';
import { cartCrossIcon, appleIcon, masterCardIcon, forwardArrow, contactIcon } from '../../../assets/images/newImages';
import { useTranslation } from 'react-i18next';

function PayMethodModal(props) {
    const { t,i18n } = useTranslation();
    const {classes,pay,closeCart,payBtnClick,currentLanguage } = props;
    return (
        <>
            <PayModel
                open={pay}
                onClose={''}
                className={classes.modalPay}
            >
                <div className={`${classes.paperPay}`}>
                    <div className='paymodal-main-div'>
                        <div className={currentLanguage?.left_to_right === 0 ? 'payModal-div-rtl' : 'payModal-div'} >
                            <div  className={currentLanguage?.left_to_right === 0 ? 'applePay-rtl applePay':'applePay'}>
                                <img src={appleIcon} className='appleIcon' />
                                <span className='applePay-head'>Pay</span>
                            </div>
                            <div className={currentLanguage?.left_to_right === 0 ? 'paymodal-cartCross-rtl paymodal-cartCross': 'paymodal-cartCross'} >
                                <img src={cartCrossIcon} onClick={closeCart} />
                            </div>
                        </div>
                        <div className={currentLanguage?.left_to_right === 0 ? 'payModal-second-div-rtl payModal-second-div':'payModal-second-div'} >
                            <div className='paymodal-div'>
                                <img src={masterCardIcon} className={currentLanguage?.left_to_right === 0 ? 'masterIcon-rtl masterIcon':'masterIcon'}  />
                                <div className='paymodal-text'>
                                    <span className={currentLanguage?.left_to_right === 0 ? 'paymodal-t paymodal-t-rtl':'paymodal-t'}> {t('saudiCentralBank')} </span>
                                    <span className='paymodal-text-number'>1234 **** **** **12</span>
                                </div>
                            </div>
                            <div className={currentLanguage?.left_to_right === 0 ? 'forward-Arrow-rtl':'forward-Arrow'} >
                                <img src={forwardArrow} />
                            </div>
                        </div>

                        <div  className={currentLanguage?.left_to_right === 0 ? 'payModal-third-div payModal-third-div-rtl':'payModal-third-div'}>
                            <div className='contact-div'>
                                <img className={currentLanguage?.left_to_right === 0 ? 'contactIcon contactIcon-rtl':'contactIcon'} src={contactIcon} />
                                <div className='contact-text-div'>
                                    <span className={currentLanguage?.left_to_right === 0 ? 'contact-text contact-text-rtl':'contact-text'}> {t('ContactBank')}</span>
                                    <span className='contact-number'>Ali Salman + (571) 777-7770</span>
                                    <span className='contact-email'>example@gmail.com </span>
                                </div>
                            </div>
                            <div className={currentLanguage?.left_to_right === 0 ? 'forward-Arrow-rtl':'forward-Arrow'}>
                                <img src={forwardArrow} />
                            </div>
                        </div>

                        <div className={currentLanguage?.left_to_right === 0 ? 'paymodal-payment-div paymodal-payment-div-rtl':'paymodal-payment-div'} >
                            <div className={currentLanguage?.left_to_right === 0 ? 'payment-div-text-rtl':'payment-div-text'} >
                                <p className={currentLanguage?.left_to_right === 0 ? 'final-amt final-amt-rtl':'final-amt'}> {t('finalAmt')} </p>
                                <h3 className='final-price'>SAR 402.24</h3>
                            </div>
                            <div className={currentLanguage?.left_to_right === 0 ? 'payButton-div-rtl':'payButton-div'} >
                                <button className={currentLanguage?.left_to_right === 0 ? 'payButton payButton-rtl':'payButton' }  onClick={payBtnClick}>{t('pay')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </PayModel>
        </>
    )
}
export default PayMethodModal;